<template>
  <div class="mobile-schedule-view">
    <div class="mobile-schedule-view__header">
      <div class="mobile-schedule-view__date">
        <span class="">{{ formattedDate(row.turn_date).dateFormatted }}</span>
        <span class="color-neutral-400 ml-1">{{ formattedDate(row.turn_date).dayOfWeek }}</span>
      </div>
      <div class="mobile-schedule-view__point">{{ row.point }}</div>
      <div class="mobile-schedule-view__tag">
        <base-tag v-if="row.turn_order === 0" />
      </div>
      <div class="mobile-schedule-view__period">{{ formattedTime(row) }} - {{ formattedTime(row, false) }}</div>
      <div class="mobile-schedule-view__hour color-neutral-400">{{ formattedSummarizeWorkTime(row) }}</div>
    </div>
    <div class="mobile-schedule-view__footer">
      <div class="mobile-schedule-view__coworkers">
        <span
          v-for="(coworker, index) in stringToArray(row.coworkers)"
          :key="index"
          class="mobile-schedule-view__coworker"
        >
          {{ coworker }}
        </span>
      </div>
      <div class="ml-2">
        <base-icon path="arrow-up-right-line.svg" width="16" height="16" screen-size-dependency @click="openSchedule" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { formattedDate } from "@/components-js/dateFormat";
import { formattedSummarizeWorkTime, formattedTime, stringToArray } from "../../helpers";
import BaseTag from "@/components/BaseTag";
import BaseIcon from "@/components/BaseIcon";

const emits = defineEmits(["openSchedule"]);
const props = defineProps({
  row: { type: Object },
});

const openSchedule = () => {
  emits("openSchedule", {
    currentPoint: { id: props.row.id_point, name: props.row.point },
    currentDate: props.row.turn_date,
  });
};
</script>

<style scoped lang="scss">
@import "@/styles/ui/fontSize";

.mobile-schedule-view {
  display: flex;
  flex-direction: column;
  gap: var(--sp-3);
  padding: var(--sp-ex-10);
  border-radius: 5px;
  background: var(--color-neutral-50);
  @include add-font-face("body-4");

  &__header {
    display: grid;
    grid-template-columns:
      minmax(10px, 20fr)
      minmax(10px, 35fr)
      minmax(10px, 9fr)
      minmax(10px, 26fr)
      minmax(10px, 18fr);
    max-width: 1149px;
    min-width: 100%;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
  }

  &__tag {
    @include add-font-face("body-2");
  }

  &__hour,
  &__period {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  &__coworkers {
    display: flex;
    flex-wrap: wrap;
    gap: var(--sp-3);
  }

  &__coworker {
    @include add-font-face("body-3", var(--color-neutral-400));

    &:not(:last-child) {
      &:after {
        content: "•";
        display: inline-flex;
        padding: 0 var(--sp-1);
        color: var(--color-neutral-900);
      }
    }
  }
}
</style>
